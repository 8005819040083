"use client"
import React, { useEffect, useRef, useState } from "react";
import { ContextData, storeAssigned, _logoutFunction } from "@/services/Methods/normalMethods";
import { _storeCustomerTypes } from "@/services/Methods/commonPayload";
import localStorageCall from "@/services/Methods/localstorage.hook";
import { _getCountryCodeApi } from "@/services/Redux/Reducer/CustomerSlice";
import { createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import withRouteHOC from "@/HOC/withRouterHOC"
import withMiniCartHOC from "@/HOC/withMinCartHOC";
import _ from "lodash";
// import "react-datepicker/dist/react-datepicker.css";
import { getOrders } from "@/services/Redux/Reducer/OrderSlice";
import Header from "@/comman/Header/Header";
import ShoppingCartComponent from "@/comman/ShoppingCartComponent";
import Footer from "@/comman/Footer/Footer";
import useScript from "../../services/Methods/UseScript.hook";
import JoinPopup from "@/comman/JoinPopup";
import * as Sentry from "@sentry/react";
import ButtonComponent from "@/Inputs/ButtonComponent";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import useIpDetection from "../../../hooks/useIpDetection";
import { useRouter } from "next/navigation";
import UseAdminPortal from "../../../hooks/UseAdminPortal.hook";


// const STORES = ["us", "ca"];

// const Redirect_STORES = ['us', 'au', 'br', 'bs', 'ca', 'co', 'hk', 'id', 'jm', 'my', 'nz', 'pa', 'pe', 'ph', 'sg', 'eu', 'at', 'be', 'bg',
//     'bw', 'ch', 'cy', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fr', 'gb', 'gh', 'gr', 'hr', 'hu', 'ie', 'in', 'is', 'it', 'jp', 'mx',
//     'ke', 'lt', 'mt', 'mz', 'na', 'nc', 'ng', 'nl', 'no', 'pl', 'pt', 're', 'ro', 'rs', 'ru', 'se', 'si', 'sk', 'tz', 'ug', 'za', 'zm', 'zw','tw'];
const STORES = ['us', 'au', 'br', 'bs', 'ca', 'co', 'hk', 'id', 'jm', 'my', 'nz', 'pa', 'pe', 'ph', 'sg', 'eu', 'at', 'be', 'bg',
    'bw', 'ch', 'cy', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fr', 'gb', 'gh', 'gr', 'hr', 'hu', 'ie', 'in', 'is', 'it', 'jp', 'mx',
    'ke', 'lt', 'mt', 'mz', 'na', 'nc', 'ng', 'nl', 'no', 'pl', 'pt', 're', 'ro', 'rs', 'ru', 'se', 'si', 'sk', 'tz', 'ug', 'za', 'zm', 'zw', 'tw', 'pf'];

const Template = ({ children, ...props }) => {
    const { pathname, searchParams, params, redirect, isUserLogin, checkoutRef } = props;
    const [showCountries, setShowCountries] = useState(false);
    const dispatch = useDispatch();
    const menuRef = useRef(null);
    const buttonRef = useRef(null);
    const [orders, setOrders] = useState([])
    const [selectedStore, setSelectedStore] = useState({ defaultCode: 'us', newCode: 'us' });
    const [userIp, setUserIp] = useState()
    const [storeAssign, setStoreAssign] = useState();
    const router = useRouter();

    const { frontEndSettings, getFrontEndSettings } = UseAdminPortal()

    useEffect(() => {
        getFrontEndSettings()
    }, [])

    console.log(frontEndSettings, "usersettingintemplate")
    const handleOutsideClick = (event) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target) &&
            event.target !== buttonRef.current
        ) {
            setShowCountries(false);
        }
    };



    useEffect(() => {
        localStorageCall().removeItem('activeStep');
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const MEMBERSHIP_AVAILABLE = _.find(localStorageCall().getItem('normalProducts'), { frequency: "member" }) || null;

    if (!_.includes(pathname, 'main-enrollment') && MEMBERSHIP_AVAILABLE) {
        localStorageCall().removeItem('normalProducts');
    }


    const getData = createSelector(
        state => state.CustomerSlice,
        state => state?.LoginSlice?.alluserData,
        state => state?.PaymentSlice?.paymentScript,
        ({ countryData, isLoading }, userData, paymentScript) => ({
            isLoading,
            countryCodes: _.map(countryData, 'countryCode')?.length > 0 ? _.map(countryData, 'countryCode') : [],
            countyObjects: _.map(countryData, (row) => ({ code: row?.countryCode, name: row?.countryName, currency: row?.currency })),
            userData: userData, paymentScript,
        })
    );

    const { isLoading, countryCodes, countyObjects, paymentScript, userData } = useSelector((state) => getData(state));

    useIpDetection(STORES, params?.store, router, pathname, isUserLogin, setUserIp);

    useScript(paymentScript);

    useEffect(() => { dispatch(_getCountryCodeApi()); }, []);

    useEffect(() => {

        const DEFAULT_STORE = localStorageCall()?.getSingleItem('store');

        if (!DEFAULT_STORE) {
            if (STORES?.includes(params?.store)) {
                localStorageCall().setItem('store', params?.store)
            }
        } else {
            if (DEFAULT_STORE !== params?.store) {
                if (STORES?.includes(params?.store)) {
                    localStorageCall().setItem('store', params?.store);
                    setSelectedStore({ defaultCode: params?.store, newCode: params?.store });
                } else {
                    setSelectedStore({ defaultCode: DEFAULT_STORE, newCode: DEFAULT_STORE });
                }
            } else {
                setSelectedStore({ defaultCode: DEFAULT_STORE, newCode: DEFAULT_STORE });
            }
        }

        const weglotScript = document.createElement('script');
        weglotScript.src = "https://cdn.weglot.com/weglot.min.js";
        weglotScript.async = true;
        weglotScript.onload = () => {
            if (window.Weglot) {
                Weglot.initialize({
                    api_key: 'wg_dcb2ef458c9a4e857e5eefd844a45f9d0',
                });
            }
        };

        document.head.appendChild(weglotScript);

        const zendeskScript = document.createElement('script');
        zendeskScript.id = "ze-snippet";
        zendeskScript.src = "https://static.zdassets.com/ekr/snippet.js?key=c1c6e6aa-6c00-48c7-8f9a-1936ac92a03d";
        zendeskScript.async = true;

        document.head.appendChild(zendeskScript);

        // Cleanup function to remove scripts on unmount
        return () => {
            document.head.removeChild(weglotScript);
            document.head.removeChild(zendeskScript);
        };

    }, [pathname]);


    useEffect(() => {
        if (STORES?.includes(params?.store)) {
            localStorageCall().setItem('store', params?.store)
        }

    }, [pathname]);

    useEffect(() => {
        if (isUserLogin && userData === null) {
            _storeCustomerTypes(dispatch, isUserLogin, searchParams);
        }

        // dispatch(getOrders(null, isUserLogin, (res) => {
        //     setOrders(res?.data)
        // }))
    }, [isUserLogin]);


    const [joinPopup, setJoinPopup] = useState(false);

    if (userData?.emailAddress) {
        Sentry.setUser({ id: userData?.customerId, email: userData?.emailAddress });
        Sentry.setTag("userID", userData?.customerId);
    }

    const [showPopup, setShowPopup] = useState(false);

    const UserCountry = userData?.countryCode;
    const StoreASSigned = storeAssigned();

    const LOCAL_STORAGE_KEY = 'showPopupDismissed';
    useEffect(() => {
        if (localStorageCall().getItem(LOCAL_STORAGE_KEY)) {
            setShowPopup(false);
        }
    }, [setShowPopup]);

    // const handleClose = () => {
    //     localStorageCall().setItem(LOCAL_STORAGE_KEY, 'true');
    //     setShowPopup(false);
    // };
    const Localkey = localStorageCall()?.getItem(LOCAL_STORAGE_KEY);

    const userIpCountry = localStorageCall().getSingleItem('IpCountry');
    console.log("userIpCountry", userIpCountry);
    const storeAssignUppercase = StoreASSigned ? StoreASSigned.toUpperCase() : null;
    // console.log("storeAssignUppercase !== userIpCountry", storeAssignUppercase !== userIpCountry, "keyvalue", !Localkey);

    // useEffect(() => {
    //     if (!Localkey) {
    //         console.log("inside my useEffect 1");
    //         setShowPopup(true);
    //     }
    // }, [userData, userIpCountry]);

    useEffect(() => {
        const storeAssignUppercase = StoreASSigned ? StoreASSigned.toUpperCase() : null;
        setStoreAssign(storeAssignUppercase);

        // if (!searchParams.get('ssokey') && UserCountry && storeAssignUppercase && (storeAssignUppercase !== userIpCountry) && (UserCountry !== storeAssignUppercase) && !Localkey) {
        //     setShowPopup(true);
        // }


    }, [userData, pathname]);
    let cartPositionCss = frontEndSettings?.share_cart_position?.includes("bottom_right") ? "fixed bottom-[120px] right-0 bg-black text-white border border-white border-r-[transparent] rounded-[10px_0px_0px_10px] rounded-md px-4 py-3 !no-underline  " : "" || frontEndSettings?.share_cart_position?.includes("bottom_left") ? "!no-underline fixed bottom-[120px] left-0 bg-black text-white rounded-[0_10px_10px_0] px-4 py-2 border border-white border-l-[transparent]" : "  "


    return (
        <>
            <ContextData.Provider value={{
                showCountries, userData, countryCodes, setShowCountries, countyObjects, dispatch, STORES, handleOutsideClick,
                buttonRef, menuRef, orders, selectedStore, setSelectedStore, checkoutRef, UserCountry, storeAssignUppercase, userIpCountry, Localkey, setShowPopup, showPopup, storeAssign, setStoreAssign, LOCAL_STORAGE_KEY, pathname, ...props
            }
            }>
                <Header  {...{
                    showCountries, userData, countryCodes, setShowCountries, countyObjects, dispatch, STORES, handleOutsideClick, buttonRef,
                    menuRef, joinPopup, setJoinPopup, selectedStore, setSelectedStore,
                    ...props
                }} />
                <div>{children}</div>
                <Footer {...{ joinPopup, setJoinPopup, userData }} />
                <ShoppingCartComponent
                    section={_.includes(pathname, 'distributor') ? 'distributor' : 'normal'}
                    pathname={pathname}
                    _changeFrequencyOfProducts={props?._changeFrequencyOfProducts}
                    _minCartQuantityIncDec={props?._minCartAndViewCartQuantityIncDec}
                    openMiniCart={props?.openMiniCart}
                    setOpenMiniCart={props?.setOpenMiniCart}
                    _deleteProductFromCart={props?._deleteProductFromCart}
                    productData={props?.addToCartProducts?.normalProducts}
                    _autoShipCheck={props?._autoShipCheck} />
                {
                    props?.addToCartProducts?.normalProducts.length > 0 &&
                    <div>
                        {
                            frontEndSettings?.share_cart_enabled &&
                            (frontEndSettings?.share_cart_position?.includes("bottom_right") ||
                                frontEndSettings?.share_cart_position?.includes("bottom_left")) && (
                                <div className="bg-sky-500">
                                    <button className={`${cartPositionCss}`}>ShareMycart</button>
                                </div>
                            )
                        }
                    </div>
                }

            </ContextData.Provider>
            <JoinPopup {...{ joinPopup, setJoinPopup }} />

        </ >
    );
}

export default withRouteHOC(withMiniCartHOC(Template, 'normal'));